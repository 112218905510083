export default new function hero($) {
  this.init = () => {
    this.addEvents();
  };

  this.addEvents = () => {
    $(document).on('click', '.component--content-blocks .read-more', this.eventHandlers.onReadMoreClick.bind(this));
  };

  this.eventHandlers = {
    onReadMoreClick({ currentTarget }) {
      const text = $(currentTarget).prev('.component--content-blocks__block-content-text');
      const buttonText = $(currentTarget).find('span');
      const textReadMore = $(currentTarget).attr('data-text-more');
      const textReadLess = $(currentTarget).attr('data-text-less');
      text.slideToggle();
      buttonText.text(buttonText.text() === textReadLess ? textReadMore : textReadLess);
    },
  };

  return this.init;
}(jQuery);
