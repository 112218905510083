// ripped from http://www.javascriptkit.com/dhtmltutors/sticky-hover-issue-solutions.shtml#dynamichover

export default function touchTest() {
  let isTouch = false;
  let isTouchTimer;
  let curRootClass = '';

  const addTouchClass = () => {
    clearTimeout(isTouchTimer);
    isTouch = true;

    if (curRootClass !== 'is-touch') {
      curRootClass = 'is-touch';
      document.documentElement.classList.add(curRootClass);
    }

    isTouchTimer = setTimeout(() => {
      isTouch = false;
    }, 500);
  };

  const removeTouchClass = () => {
    if (!isTouch && curRootClass === 'is-touch') {
      isTouch = false;
      curRootClass = '';
      document.documentElement.classList.remove('is-touch');
    }
  };

  document.addEventListener('touchstart', addTouchClass, false);
  document.addEventListener('mouseover', removeTouchClass, false);
}
