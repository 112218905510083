export function deparam(search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params = {};

  hashes.forEach((hash) => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });

  return params;
}

export function param(obj) {
  const str = [];

  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
}
