import inViewport from '../util/in-viewport';
import debounce from '../util/debounce';

export default new function historyPage($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $(window).on('DOMContentLoaded load resize scroll', debounce(this.eventHandlers.onScroll.bind(this), 10));
  };

  this.eventHandlers = {
    onScroll() {
      $('.timeline__entry').each((i, el) => {
        const $el = $(el);

        if ($el.hasClass('in-viewport')) {
          return;
        }

        if (inViewport($el, -150)) {
          $el.addClass('in-viewport');
        }
      });
    },
  };

  this.helpers = {
  };

  return this.init;
}(jQuery);
