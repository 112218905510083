export default new function news($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
    const width = $(window).width();
    let slidesPerView;

    if (width > 748) {
      slidesPerView = 3;
    } else if (width > 436) {
      slidesPerView = 2;
    } else {
      slidesPerView = 1;
    }

    $('.component--news .swiper-container').each((i, el) => {
      const swiper = new window.Swiper(el, {
        pagination: '.swiper-pagination',
        slidesPerView: slidesPerView,
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
      });
    });
  };

  this.addEvents = () => {

  };

  this.eventHandlers = {

  };

  this.helpers = {
  };

  return this.init;
}(jQuery);
