export default new function jobOpenings($) {
  let initialHeightSet = false;

  this.init = () => {
    if ($('.component--job-openings').length) {
      this.addEvents();
      this.modifyDOM();
    }
  };

  this.modifyDOM = () => {
    setTimeout(() => {
      $('#workbuster_iframe').attr('onload', '');
    }, 2000);
  };

  this.addEvents = () => {
    window.addEventListener('message', this.helpers.handlePostMessage, false);
  };

  this.eventHandlers = {
  };

  this.helpers = {
    handlePostMessage: (e) => {
      const key = e.message ? 'message' : 'data';
      let data = e[key];

      if (!data) {
        return;
      }

      data = JSON.parse(data);

      if (data.WB_HEIGHT) {
        if (initialHeightSet) {
          $('html, body').animate({
            scrollTop: $('#workbuster_iframe').offset().top - ($(window).height() / 4),
          }, 0);
        }

        initialHeightSet = true;
      }
    },
  };

  return this.init;
}(jQuery);
