import template from 'lodash/template';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import { param, deparam } from '../util/query-params';

export default new function findRetailerMap($) {
  const _this = this;
  this.apiLoaded = false;
  this.map = undefined;
  this.markers = [];
  this.markerClusterer = undefined;
  this.selectedMarker = undefined;
  this.selectedServices = [];
  this.selectedSubServices = [];

  this.init = () => {
    if (!$('.component--find-retailer-map').length) {
      return;
    }

    this.addEvents();
    this.modifyDOM();

    window.googleMapsInitializer = this.helpers.initializeMap;
    $.getScript('//maps.googleapis.com/maps/api/js?key=AIzaSyBR2dGYbB2KE08Vtnn5UrMr38w75fp6PFo&libraries=geometry,places&callback=googleMapsInitializer').success(() => {
      this.apiLoaded = true;
    });
  };

  this.modifyDOM = () => {
    $('.js-services, .js-sub-services').select2({
      width: '100%',
      language: window.wp_locale,
    });
  };

  this.addEvents = () => {
    $('body').on('click', '.js-use-geolocation', this.eventHandlers.getPosition.bind(this))
             .on('click', '.find-retailer__filter-toggler', this.eventHandlers.onToggleFilter.bind(this))
             .on('change', '.js-services', this.eventHandlers.onServiceChange.bind(this))
             .on('change', '.js-sub-services', this.eventHandlers.onSubServiceChange.bind(this));
  };

  this.eventHandlers = {
    onToggleFilter() {
      this.helpers.toggleFilter();
    },

    onServiceChange(e) {
      const selectedServicesValue = $(e.currentTarget).val();

      if (selectedServicesValue) {
        this.selectedServices = [parseInt(selectedServicesValue, 10)];
      } else {
        this.selectedServices = [];
      }

      this.helpers.setupMarkers();
      this.helpers.setupMarkersList();
      this.helpers.fitBounds();
      this.helpers.updateURL();

      $('.js-sub-services.active').val('').trigger('change');
      $('.js-sub-services').removeClass('active');

      if (this.selectedServices.length) {
        this.selectedServices.forEach((id) => {
          const $el = $(`.js-sub-services[data-service-term-id=${id}]`);

          $el.addClass('active').prop('disabled', false);

          if ($el.find('option').length === 2) {
            $el.val('' + $el.find('option')[1].value).trigger('change');
          }
        });
      } else {
        $('.js-sub-services.placeholder').addClass('active');
      }
    },

    onSubServiceChange(e) {
      const selectedSubServicesValue = $(e.currentTarget).val();

      if (selectedSubServicesValue) {
        this.selectedSubServices = [parseInt(selectedSubServicesValue, 10)];
      } else {
        this.selectedSubServices = [];
      }

      this.helpers.setupMarkers();
      this.helpers.setupMarkersList();
      this.helpers.fitBounds();
      this.helpers.updateURL();
    },

    getPosition() {
      this.helpers.getGeolocation();
      return false;
    },
  };

  this.helpers = {
    initializeMap() {
      const mapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        scrollwheel: false,
        center: { lat: 62.405640221642315, lng: 26.103515625 },
        zoom: 6,
      };

      _this.map = new google.maps.Map($('.find-retailer__map__canvas')[0], mapOptions);
      _this.helpers.styleMap();
      _this.helpers.setupMarkers();
      _this.helpers.setupMarkersList();
      _this.helpers.setupAutocomplete();
      _this.helpers.parseURL();
    },

    styleMap() {
      const styles = [{
        featureType: 'all',
        stylers: [
          { saturation: -80 },
          { hue: '#ADB6BF' },
          { gamma: 1.51 },
        ],
      }];

      const styledMap = new google.maps.StyledMapType(styles, { name: 'Styled Map' });

      _this.map.mapTypes.set('map_style', styledMap);
      _this.map.setMapTypeId('map_style');
    },

    selectMarkerByRetailerId(id) {
      const found = _this.markers.filter(marker => marker.retailer.ID === id);

      if (found.length) {
        this.selectMarker(found[0]);
      }
    },

    setupMarkersList() {
      const listTemplate = template($('#retailer-list-template').html());

      const onSelect = marker => () => {
        this.selectMarker(marker);
      };

      const onClose = () => {
        this.unselectMarker();
      };

      const renderList = debounce(() => {
        const markers = _this.helpers.getBoundMarkersByDistance();
        const fragment = document.createDocumentFragment();

        markers.forEach((marker) => {
          let selected = false;

          if (_this.selectedMarker && _this.selectedMarker.retailer.ID === marker.retailer.ID) {
            selected = true;
          }

          const $el = $(listTemplate({ retailer: marker.retailer, selected }));

          if (selected) {
            $el.on('click', '.js-close', onClose);
          } else {
            $el.on('click', onSelect(marker));
          }

          fragment.appendChild($el[0]);
        });

        $('.find-retailer__map__list').html(fragment);
      }, 500);

      google.maps.event.addListener(_this.map, 'bounds_changed', renderList);
      renderList();
    },

    setupMarkers() {
      let retailers = window.wp_retailers;

      retailers = this.applyServiceFilter(retailers);
      retailers = this.applySubServiceFilter(retailers);

      this.clearAllMarkers();

      retailers.forEach((r) => {
        const position = new google.maps.LatLng(r.meta.location.lat,
                                                r.meta.location.lng);
        const marker = new google.maps.Marker({
          position,
          map: _this.map,
          title: r.post_title,
          retailer: r,
          icon: '/content/themes/kgk/dist/images/map-pin.png',
        });

        google.maps.event.addListener(marker, 'click', () => {
          this.selectMarker(marker, true);
        });

        _this.markers.push(marker);
      });

      _this.markerClusterer = new MarkerClusterer(_this.map, _this.markers, {
        imagePath: '/content/themes/kgk/dist/images/marker-clusterer/m',
      });
    },

    clearAllMarkers() {
      if (_this.markerClusterer) {
        _this.markerClusterer.clearMarkers();
      }

      _this.markers.forEach(marker => marker.setMap(null));
      _this.markers = [];
    },

    applyServiceFilter(retailers) {
      if (_this.selectedServices.length) {
        return retailers.filter(r => r.services.map(s => s.term_id)
                        .some(service => _this.selectedServices.indexOf(service) >= 0));
      }

      return retailers;
    },

    applySubServiceFilter(retailers) {
      if (_this.selectedSubServices.length) {
        return retailers.filter(r => r.services.map(s => s.term_id)
                        .some(service => _this.selectedSubServices.indexOf(service) >= 0));
      }

      return retailers;
    },

    setupAutocomplete() {
      const $locators = $('.js-locator');
      const autocompletes = [];

      if (!$locators.length) {
        return;
      }

      /* eslint-disable */
      // From http://stackoverflow.com/a/11703018/827642
      function pacSelectFirst(input) {
        // store the original event binding function
        var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;

        function addEventListenerWrapper(type, listener) {
          // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
          // and then trigger the original listener.
          if (type === "keydown") {
            var orig_listener = listener;
            listener = function(event) {
              var suggestion_selected = $(".pac-item-selected").length > 0;
              if (event.which === 13 && !suggestion_selected) {
                  var simulated_downarrow = $.Event("keydown", {
                      keyCode: 40,
                      which: 40
                  });
                  orig_listener.apply(input, [simulated_downarrow]);
              }

              orig_listener.apply(input, [event]);
            };
          }

          _addEventListener.apply(input, [type, listener]);
        }

        input.addEventListener = addEventListenerWrapper;
        input.attachEvent = addEventListenerWrapper;
        autocompletes.push(new google.maps.places.Autocomplete(input, { componentRestrictions: { country: 'fi' } }));
      }
      /* eslint-enable */

      $locators.each(function() {
        pacSelectFirst(this);
      });

      autocompletes.forEach((ac) => {
        google.maps.event.addListener(ac, 'place_changed', () => {
          _this.helpers.fillInAddress(ac.getPlace());
        });
      });
    },

    toggleFilter() {
      $('.find-retailer__filter-toggler').toggleClass('find-retailer__filter-toggler--active');
      $('.find-retailer__controls__section--filter').slideToggle();
    },

    fillInAddress(place) {
      if (place.geometry) {
        _this.map.setCenter(place.geometry.location);

        if (place.geometry.viewport) {
          _this.map.fitBounds(place.geometry.viewport);
          this.zoomOutUntilBoundMarker();
        }
      }
    },

    zoomOutUntilBoundMarker() {
      let attempts = 0;

      while (this.getBoundMarkersByDistance().length < 6 && attempts < 8) {
        console.log("Zooming out, current zoom level: " + _this.map.getZoom());
        _this.map.setZoom(_this.map.getZoom() - 1);
        attempts += 1;
      }
    },

    getBoundMarkersByDistance() {
      const markers = [];

      for (let i = 0; i < _this.markers.length; i++) {
        if (_this.map.getBounds().contains(_this.markers[i].getPosition())) {
          markers.push(_this.markers[i]);
        }
      }

      return sortBy(markers,
        m => google.maps.geometry.spherical.computeDistanceBetween(m.getPosition(),
                                                                   _this.map.getCenter()));
    },

    selectMarker(marker, dontZoom) {
      if (_this.selectedMarker) {
        if (marker.retailer.ID === _this.selectedMarker.retailer.ID) {
          this.unselectMarker();
        } else {
          _this.selectedMarker.setIcon('/content/themes/kgk/dist/images/map-pin.png');
        }
      }

      _this.selectedMarker = marker;

      if (!dontZoom) {
        _this.map.setZoom(14);
      }

      _this.map.panTo(marker.getPosition());
      google.maps.event.trigger(_this.map, 'resize');
      marker.setIcon('/content/themes/kgk/dist/images/map-pin-active-white.png');
      $('.find-retailer__map__list').scrollTop(0);
      this.updateURL();
    },

    unselectMarker() {
      if (_this.selectedMarker) {
        $('.find-retailer__map').removeClass('find-retailer__map--has-selected');
        $('.find-retailer__map__items').empty();
        _this.selectedMarker.setIcon('/content/themes/kgk/dist/images/map-pin.png');
        _this.selectedMarker = null;
        _this.helpers.setupMarkersList();
        this.updateURL();
      }
    },

    getGeolocation() {
      let positionRetrieved = false;

      if (navigator.geolocation) {
        $('.js-use-geolocation').addClass('spinning');

        const onFail = () => {
          $('.js-use-geolocation').removeClass('spinning');
        };

        // After 15 seconds with no callback, assume it failed
        setTimeout(() => {
          if (!positionRetrieved) {
            onFail();
          }
        }, 15000);

        navigator.geolocation.getCurrentPosition((position) => {
          positionRetrieved = true;
          $('.js-use-geolocation').removeClass('spinning');
          _this.map.setCenter(new google.maps.LatLng(position.coords.latitude,
                                                     position.coords.longitude));
          _this.map.setZoom(12);
          _this.helpers.zoomOutUntilBoundMarker();
        }, onFail);
      }
    },

    fitBounds() {

      if(!_this.markers.length) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < _this.markers.length; i++) {
        bounds.extend(_this.markers[i].getPosition());
      }

      _this.map.fitBounds(bounds);
    },

    updateURL() {
      const params = {};

      if (_this.selectedMarker) {
        params.retailerId = _this.selectedMarker.retailer.ID;
      }

      if (_this.selectedServices) {
        params.selectedServices = _this.selectedServices.join(',');
      }

      if (_this.selectedSubServices) {
        params.selectedSubServices = _this.selectedSubServices.join(',');
      }

      window.history.pushState({}, '', '?' + param(params));
    },

    parseURL() {
      const queryParams = deparam(window.location.search);
      let urlContainsSelectedServices = false;

      if (queryParams.retailerId) {
        setTimeout(() => {
          _this.helpers.selectMarkerByRetailerId(parseInt(queryParams.retailerId, 10));
        }, 1000);
      }

      if (queryParams.selectedServices) {
        $('.js-services').val(queryParams.selectedServices.split(',')).trigger('change');
        urlContainsSelectedServices = true;
      }

      if (queryParams.selectedSubServices) {
        $('.js-sub-services.active').val(queryParams.selectedSubServices.split(',')).trigger('change');
        urlContainsSelectedServices = true;
      }

      if (urlContainsSelectedServices && $('.find-retailer__filter-toggler').is(':visible')) {
        this.toggleFilter();
      }
    },
  };

  return this.init;
}(jQuery);
