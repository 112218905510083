import touchTest from '../util/touch-test';
import hamburgerNav from '../util/hamburger-navigation';
import header from '../components/header';
import postsSlider from '../components/posts-slider';
import squareBlocks from '../components/square-blocks';
import industryExplorer from '../components/industry-explorer';
import sidebarNav from '../components/sidebar-nav';
import stats from '../components/stats';
import jobOpenings from '../components/job-openings';
import hero from '../components/hero';
import findRetailerMap from '../components/find-retailer-map';
import news from '../components/news';
import contentBlocks from '../components/content-blocks';

export default new function common($) {
  this.init = () => {
    touchTest();
    header();
    hamburgerNav();
    postsSlider();
    squareBlocks();
    industryExplorer();
    stats();
    jobOpenings();
    sidebarNav();
    hero();
    findRetailerMap();
    news();
    this.addEvents();
    this.modifyDOM();
    contentBlocks();
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {

  };

  this.eventHandlers = {

  };

  this.helpers = {
  };

  return this.init;
}(jQuery);
