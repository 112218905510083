export default new function hamburgerNav($) {
  let $html;
  let $mobileNav;
  let $mobileNavToggle;
  let $mobileSubNavToggle;

  this.init = () => {
    this.setVars();
    this.modifyDOM();
    this.addEvents();
  };

  this.setVars = () => {
    $html = $('html');
    $mobileNav = $('.mobile-navigation');
    $mobileNavToggle = $('.mobile-navigation-toggle');
    $mobileSubNavToggle = $mobileNav.find('.page_item_has_children');
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $mobileNavToggle.on('click', this.eventHandlers.showMobileMenu);
    $mobileSubNavToggle.on('click', this.eventHandlers.showMobileSubMenu);
  };

  this.eventHandlers = {
    showMobileMenu() {
      if ($mobileNavToggle.hasClass('active')) {
        $mobileNavToggle.removeClass('active');
        $mobileNav.removeClass('mobile-navigation--collapsed');
        $html.removeClass('mobile-navigation-open');
      } else {
        $mobileNavToggle.addClass('active');
        $mobileNav.addClass('mobile-navigation--collapsed');
        $html.addClass('mobile-navigation-open');
        $(window).scrollTop(0);
      }
    },

    showMobileSubMenu(e) {
      if (e.target.nodeName === 'A') {
        return;
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
      $(this).toggleClass('collapsed').children('ul').toggle();
    }
  };

  this.helpers = {
  };

  return this.init;
}(jQuery);
