export default new function hero($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $('body').on('click', '.hero__video-btn', this.eventHandlers.onVideoButtonClick.bind(this))
             .on('click', '.hero__video__close-btn', this.eventHandlers.onVideoCloseButtonClick.bind(this))
             .on('click', '.hero__overlay--video', this.eventHandlers.onVideoOverlayClick.bind(this));
    $('.hero--video__overlay').on('click', 'i', this.eventHandlers.videoSoundToggle.bind(this));
  };

  this.eventHandlers = {
    videoSoundToggle() {
      let video = document.getElementById("hero-video");

      video.muted = !video.muted;
      $('.hero--video__overlay').find('.fa-volume-up').toggle();
      $('.hero--video__overlay').find('.fa-volume-off').toggle();
    },

    onVideoButtonClick(e) {
      this.helpers.openVideo($(e.currentTarget).closest('.hero'));
      return false;
    },

    onVideoCloseButtonClick(e) {
      this.helpers.closeVideo($(e.currentTarget).closest('.hero'));
      return false;
    },

    onVideoOverlayClick(e) {
      this.helpers.closeVideo($(e.currentTarget).closest('.hero'));
      return false;
    },
  };

  this.helpers = {
    openVideo($hero) {
      const $heroVideo = $hero.find('.hero__video');
      const $iframe = $heroVideo.find('iframe');
      $heroVideo.addClass('hero__video--active');
      $iframe.prop('src', $iframe.data('src'));
    },

    closeVideo($hero) {
      const $heroVideo = $hero.find('.hero__video');
      const $iframe = $heroVideo.find('iframe');
      $heroVideo.removeClass('hero__video--active');
      $iframe.removeAttr('src');
    },
  };

  return this.init;
}(jQuery);
