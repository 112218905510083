const $window = jQuery(window);

export default function inViewport(el, margin = 0) {
    if (el instanceof jQuery) {
        el = el[0];
    }

    const rect = el.getBoundingClientRect();
    const yAxis = (rect.bottom + margin <= $window.height());
    const xAxis = (rect.left < $window.width());

    return (yAxis && xAxis);
}
