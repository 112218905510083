import Router from './util/router';
import common from './routes/common';
import brandsPage from './routes/brands-page';
import historyPage from './routes/history-page';

const routes = {
  common,
  page_template_template_brands_page: brandsPage,
  page_template_template_history_page: historyPage,
};

// Load Events
jQuery(document).ready(() => new Router(routes).loadEvents());
