export default new function postsSlider($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
    const width = $(window).width();
    let slidesPerGroup;

    if (width > 748) {
      slidesPerGroup = 3;
    } else if (width > 436) {
      slidesPerGroup = 2;
    } else {
      slidesPerGroup = 1;
    }

    $('.component--posts-slider .swiper-container').each((i, el) => {
      const swiper = new window.Swiper(el, {
        pagination: '.swiper-pagination',
        slidesPerView: 'auto',
        slidesPerGroup,
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
      });
    });
  };

  this.addEvents = () => {

  };

  this.eventHandlers = {

  };

  this.helpers = {
  };

  return this.init;
}(jQuery);
