import debounce from '../util/debounce';

export default new function header($) {
  const delta = 5;
  let elHeight;
  let minHeight;
  let didScroll;
  let lastScrollTop = 0;

  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
    this.helpers.handleTimedCta('site-header');
    this.helpers.handleTimedCta('mobile-navigation');
  };

  this.addEvents = () => {
    $(window).on('DOMContentLoaded load resize scroll', debounce(this.eventHandlers.onScroll.bind(this), 10));

    setInterval(() => {
      if (didScroll) {
        let $el;

        if ($(window).width() > 748) {
          $el = $('.primary-navigation');
          elHeight = $el.outerHeight() + $('.site-header').outerHeight();
          minHeight = $('.site-header').outerHeight();
        } else {
          $el = $('.site-header, .mobile-navigation-toggle');
          elHeight = $el.outerHeight();
          minHeight = elHeight;
        }

        this.helpers.hasScrolled($el);
        didScroll = false;
      }
    }, 100);
  };

  this.eventHandlers = {
    onScroll() {
      didScroll = true;
    },
  };

  this.helpers = {
    handleTimedCta(className) {
      const $cta = $(`.${className}__cta`);
      const timedClass = `${className}__cta--timed`;
      const activeClass = 'active';

      if (!$cta.hasClass(timedClass)) {
        return;
      }

      const weekday = moment().weekday();

      if (weekday === 0 || weekday === 6) {
        return;
      }

      const start = moment($cta.data('timed-start'));
      const end = moment($cta.data('timed-end'));

      if (moment().isBetween(start, end)) {
        $cta.addClass(activeClass);
      }
    },

    hasScrolled($el) {
      const st = $(window).scrollTop();

      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      if (st < minHeight) {
        $el.removeClass('move-up').removeClass('move-down');
      } else if (st > elHeight) {
        if (st > lastScrollTop) {
          $el.removeClass('move-down').addClass('move-up');
        } else if (st + $(window).height() < $(document).height()) {
          $el.removeClass('move-up').addClass('move-down');
        }
      }

      lastScrollTop = st;
    },
  };

  return this.init;
}(jQuery);
