import inViewport from '../util/in-viewport';
import debounce from '../util/debounce';

export default new function stats($) {
  const numberEls = [];

  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
    const options = {
      useEasing: true,
      useGrouping: true,
      separator: ' ',
      decimal: ',',
      prefix: '',
      suffix: '',
    };

    $('.component--stats__number').each((index, el) => {
      numberEls.push(new window.CountUp(el, 0, parseInt(el.innerHTML, 10), 0, 2, options));
    });
  };

  this.addEvents = () => {
    if ($('.component--stats').length) {
      $(window).on('DOMContentLoaded load resize scroll', debounce(this.eventHandlers.onScroll.bind(this), 10));
    }
  };

  this.eventHandlers = {
    onScroll() {
      if (inViewport($('.component--stats'))) {
        this.helpers.handleInViewport();
      }
    },
  };

  this.helpers = {
    handleInViewport() {
      numberEls.forEach((cu) => {
        cu.start();
      });
    },
  };

  return this.init;
}(jQuery);
