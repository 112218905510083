export default new function sidebarNav($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $('body').on('click', '.entry__aside__nav .page_item_has_children', this.eventHandlers.onAncestorClick);
    $('body').on('click', '.entry__aside__nav > li', this.eventHandlers.onMobileToggle);
  };

  this.eventHandlers = {
    onAncestorClick(e) {
      if (e.target.nodeName === 'A') {
        return;
      } else {
        e.preventDefault();
        e.stopPropagation();
      }

      $(this).toggleClass('is-toggled');
    },
    onMobileToggle(e) {
      if (e.target.nodeName === 'A') {
        return;
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
      $(this).toggleClass('collapsed');
      $('.entry__aside__nav > ul').toggleClass('is-toggled');
    },
  };

  this.helpers = {
  };

  return this.init;
}(jQuery);
