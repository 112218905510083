export default new function squareBlocks($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
    this.helpers.setupSlidingBlocks();
  };

  this.addEvents = () => {

  };

  this.eventHandlers = {

  };

  this.helpers = {
    setupSlidingBlocks() {
      setInterval(() => {
        const $current = $('.component--square-blocks__block--sliding__item.active');
        const $first = $('.component--square-blocks__block--sliding__item').first();
        const $next = $current.next();
        const $prev = $current.prev();
        let $target;

        if ($next.length) {
          $target = $next;
        } else {
          $target = $first;
        }

        $current.removeClass('active');
        $target.addClass('active');
      }, 5000);
    },
  };

  return this.init;
}(jQuery);
