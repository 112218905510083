import { param, deparam } from '../util/query-params';

export default (function expander($) {
  this.opts = {
    linkable: true,
  };

  this.init = (opts) => {
    Object.assign(this.opts, opts);

    this.modifyDOM();
    this.addEvents();

    if (this.opts.linkable) {
      this.helpers.checkURL.apply(this);
    }
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $('body').on('click', '.expander-hook', this.eventHandlers.onClick)
             .on('click', '.expander__item__close', this.eventHandlers.onClose);

    if (this.opts.linkable) {
      window.onpopstate = this.helpers.checkURL;
    }
  };

  this.eventHandlers = {
    onClick: (e) => {
      if (e.target.tagName === 'A') {
        return;
      }

      const id = e.currentTarget.getAttribute('data-id');

      if (this.opts.linkable) {
        const url = window.location.pathname + '?' + param({ selectedIndustry: id });
        window.history.pushState({}, '', url);
      }

      if (this.helpers.currentlyOpen() == id) {
        this.helpers.close();
      } else if (this.helpers.currentlyOpen()) {
        this.helpers.replace(id);
      } else {
        this.helpers.open(id);
      }

      return false;
    },

    onClose: e => {
      this.helpers.close();
      return false;
    },
  };

  this.helpers = {
    checkURL: () => {
      const queryParams = deparam(window.location.search);

      if (queryParams.selectedIndustry) {
        setTimeout(_ => {
          this.helpers.open(queryParams.selectedIndustry);
        }, 200);
      }
    },

    currentlyOpen: () => {
      if ($('.expander-hook.active').length) {
        return $('.expander-hook.active').data('id');
      }
    },

    open: (id) => {
      const $content = $('.expander-holder[data-id=' + id + ']');
      const $closestContainer = $content.closest('.component--industry-explorer');
      const html = $content.html();
      const $new = $('.expander-hook[data-id=' + id + ']');
      const $viewer = $closestContainer.find('.expander-display');
      const $viewerInner = $closestContainer.find('.expander-display .container');

      $closestContainer.addClass('active');
      $new.addClass('active');
      $viewer.addClass('active');
      $new.css('margin-bottom', $content.outerHeight() + 75);

      setTimeout(() => {
        $viewerInner.html(html);
        const $iframe = $viewerInner.find('iframe');
        if ($iframe[0]) {
          $iframe[0].src += '?autoplay=1&mute=1';
        }
        $viewer.css('top', $new.offset().top + $new.outerHeight() - 55).toggleClass('animate');
        $('html, body').animate({
          scrollTop: $viewer.offset().top - ($(window).height() / 3),
        }, 500);
      }, 300);
    },

    replace: (id) => {
      const $content = $('.expander-holder[data-id=' + id + ']');
      const $closestContainer = $content.closest('.component--industry-explorer');

      if (!$closestContainer.hasClass('active')) {
        this.helpers.close();
        $('.component--industry-explorer').removeClass('active');
        this.helpers.open(id);
        return;
      }

      const html = $content.html();
      const $current = $('.expander-hook.active');
      const $new = $('.expander-hook[data-id=' + id + ']');
      const isSameRow = ($current.offset().top === $new.offset().top);
      const $viewer = $closestContainer.find('.expander-display');
      const $viewerInner = $closestContainer.find('.expander-display .container');


      $viewerInner.empty();

      if (isSameRow) {
        $current.addClass('no-transition');
        $new.addClass('no-transition');
      }

      $current.removeClass('active');
      $new.addClass('active');
      $current.css('margin-bottom', '3rem');
      $new.css('margin-bottom', $content.outerHeight() + 75);

      if (isSameRow) {
        $viewerInner.html(html);
        const $iframe = $viewerInner.find('iframe');
        if ($iframe[0]) {
          $iframe[0].src += '?autoplay=1&mute=1';
        }
        $viewer.css('top', $new.offset().top + $new.outerHeight() - 55);
        $current.removeClass('no-transition');
        $new.removeClass('no-transition');
      } else {
        setTimeout(() => {
          $viewerInner.html(html);
          const $iframe = $viewerInner.find('iframe');
          if ($iframe[0]) {
            $iframe[0].src += '?autoplay=1&mute=1';
          }
          $viewer.css('top', $new.offset().top + $new.outerHeight() - 55).toggleClass('animate');
          $('html, body').animate({
            scrollTop: $viewer.offset().top - ($(window).height() / 3),
          }, 500);
        }, 300);
      }
    },

    close: () => {
      const $current = $('.expander-hook.active');
      const $viewer = $('.expander-display');
      const $viewerInner = $('.expander-display .container');
      $('.component--industry-explorer').removeClass('active');


      $current.css('margin-bottom', '3rem');
      $('.expander-hook').removeClass('active');
      $viewer.removeClass('active');
      $viewerInner.empty();

      if (this.opts.linkable) {
        const url = '/' + window.wp_pagename + '/';
        window.history.pushState({}, '', url);
      }
    },
  };

  return this.init;
}).call({}, jQuery);
