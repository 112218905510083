import expander from '../util/expander';

export default new function industryExplorer($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
    expander({ linkable: false });
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
  };

  this.eventHandlers = {
  };

  this.helpers = {
  };

  return this.init;
}(jQuery);
