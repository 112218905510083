import { param, deparam } from '../util/query-params';

export default new function brandsPage($) {
  this.init = () => {
    this.addEvents();
    this.modifyDOM();
  };

  this.modifyDOM = () => {
    this.helpers.checkUrl();
  };

  this.addEvents = () => {
    $('body').on('change', '.brands__filter select', this.eventHandlers.onSelectChange.bind(this));
  };

  this.eventHandlers = {
    onSelectChange(e) {
      const $el = $(e.currentTarget);
      const $container = $el.closest('.brands__brand');
      const val = $el.val();

      this.helpers.updateFilter($container, val);
      this.helpers.updateUrl(val);
    },
  };

  this.helpers = {
    updateFilter($container, value) {
      $container.find('.brands__filter-item').removeClass('hidden');

      if (!value) {
        return;
      }

      const $unmatched = $container.find('.brands__filter-item').filter((index, item) => {
        const ids = $(item).data('taxonomies').toString().split(',');

        if (ids.indexOf(value) === -1) {
          return true;
        }

        return false;
      });

      $unmatched.addClass('hidden');
    },

    updateUrl(value) {
      const url = window.location.pathname + '?' + param({ selectedBrand: value });
      window.history.pushState({}, '', url);
    },

    checkUrl() {
      const queryParams = deparam(window.location.search);

      if (queryParams.selectedBrand) {
        setTimeout(_ => {
          $('.brands__filter select').first().val(queryParams.selectedBrand).change();
        }, 200);
      }
    },
  };

  return this.init;
}(jQuery);
